@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  /* flex-direction: column; */
  width: 100vw;
  height: auto;
  /* background-color: #130A2D; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .chunchun > div > .container {
  width: 100% !important;
} */

/* .chunchun {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;
