
.pre-Button{
    width: 120px;
    height: 40px;
    background-color: rgb(228, 167, 2);
    color: white;

}
.post-Button{
  width: 120px;
  height: 40px;
    background-color: rgb(73, 73, 250);
    color: white;

}

/* Upload Button */

.file-upload-wrapper{
    position:relative;
    cursor: pointer;
    display: flex;
    /* height: 7vh; */
    align-items: center;
   
  }
  .file-upload-wrapper2{
    position:relative;
    cursor: pointer;
    display: flex;
    /* height: 7vh; */
    align-items: center;
   
  }
  .file-upload-wrapper::after{
    content:attr(data-text);
    font-size: 16px;
    background:#22134E;
    padding:1px 15px;
    display: block;
    pointer-events:none;
    z-index: 20;
    line-height: 40px;
    color:#999;
    font-weight: 500;
    width: 380px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 50px;
  }
  .file-upload-wrapper2::after{
    content:attr(data-text);
    font-size: 16px;
    background-color: #313133;
    padding:1px 15px;
    display: block;
    pointer-events:none;
    z-index: 20;
    line-height: 40px;
    color:#999;
    font-weight: 500;
    width: 24vw;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 45px;
  }
  .file-upload-wrapper3::after{
    content:attr(data-text);
    font-size: 16px;
    background-color: #313133;
    padding:1px 15px;
    display: block;
    pointer-events:none;
    z-index: 20;
    line-height: 40px;
    color:#999;
    font-weight: 500;
    width: 42.5vw;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 45px;
  }
  .file-upload-wrapper::before{
    content:"Browse";
    position:absolute;
    top:0;
    right:0;
    display: inline-block;
    height:50px;
    width: 110px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: linear-gradient(to right, #00C4FF, #0074FF);
    color:#fff;
    font-weight: 700;
    z-index: 25;
    text-align: center;
    font-size: 16px;
    line-height: 49px;
    /* padding: 20px; */
    /* padding:15px; */
    text-transform: uppercase;
    pointer-events: none;
    transition: 0.5s ease-in-out; 
  }
  .file-upload-wrapper2::before{
    content:"Select";
    position:absolute;
    top:0;
    right:0;
    display: inline-block;
    height:45px;
    width: 60px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background-color: #434344;
    color:#fff;
    font-weight: 700;
    z-index: 25;
    text-align: center;
    font-size: 10px;
    line-height: 49px;
    /* padding: 20px; */
    /* padding:15px; */
    text-transform: uppercase;
    pointer-events: none;
    transition: 0.5s ease-in-out; 
  }
  .file-upload-wrapper input{
    opacity: 0;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 99;
    margin:0;
    padding:0;
    display:block;
    cursor: pointer;
    width:100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  } 
  .file-upload-wrapper2 input{
    opacity: 0;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 99;
    margin:0;
    padding:0;
    display:block;
    cursor: pointer;
    width:60vw;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  } 
  // .file-upload-wrapper3 input{
  //   opacity: 0;
  //   position:absolute;
  //   top:0;
  //   right:0;
  //   bottom:0;
  //   left:0;
  //   z-index: 99;
  //   margin:0;
  //   padding:0;
  //   display:block;
  //   cursor: pointer;
  //   width:47vw;
  //   border-bottom-left-radius: 5px;
  //   border-top-left-radius: 5px;

  // }


  /* media querry */

  @media screen and (max-width: 480px) {
    .file-upload-wrapper::after{
     width: 370px;

    }
    .upload-ip{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .upload-img{
        display: flex;
        flex-direction: column;

    }
    .upload-img img{
        margin-top: 30px;
    }
  }
 
  @media screen and (max-width:764px) {
    .file-upload-wrapper2::after{
      width: 61.5vw;
    }
    .upload-img-frame{
      margin-left: 100px;
    }
    .bar{
       width: 75vw;
    }
    
  }
  @media screen and (max-width:420px) {
    .file-upload-wrapper::after{
     width: 330px;

    }
    .file-upload-wrapper2::after{
      width: 75vw;
    }

    .pre-Button{
      width: 100px;
      height: 40px;
      background-color: rgb(228, 167, 2);
      color: white;
  
  }
  .post-Button{
    width: 100px;
    height: 40px;
      background-color: rgb(73, 73, 250);
      color: white;
      margin-left: 10px;
  
  }
  }
  