.cardSlider {
  width: 258px !important;
  height: 140px !important;
  // background: rgb(9, 121, 116);
  // background: linear-gradient(
  //   -60deg,
  //   rgb(104, 121, 9) 50%,
  //   rgb(255, 0, 157) 50%
  // );
}

.SearchCard {
  .splide__arrow {
    width: 30px !important;
    height: 30px !important;
    opacity: 1 !important;
    background-image: linear-gradient(to right, #00c4ff, #0074ff);
  }
  .splide__arrow--prev svg,
  .splide__arrow--next svg {
    fill: white !important;
    height: 12px !important;
    width: 12px !important;
  }
  .splide__arrow--next {
    margin-right: 27px !important;
    // display: none;
  }
}
.cardSliderr {
  width: 169px !important;
  height: 110px !important;
  // background: rgb(9, 121, 116);
  // background: linear-gradient(
  //   -60deg,
  //   rgb(104, 121, 9) 50%,
  //   rgb(255, 0, 157) 50%
  // );
}
.card-Slider {
  background: rgb(9, 121, 116);
}
.card-slider {
  width: 280.5px !important;
  height: 140px !important;
}
.Cardslider {
  .splide__arrow {
    width: 30px !important;
    height: 30px !important;
    opacity: 1 !important;
    background-image: linear-gradient(to right, #00c4ff, #0074ff);
  }
  .splide__arrow--prev svg,
  .splide__arrow--next svg {
    fill: white !important;
    height: 12px !important;
    width: 12px !important;
  }
  .splide__arrow--next {
    margin-right: 27px !important;
    // display: none;
  }
}
.Cardsliderr {
  .splide__arrow {
    width: 30px !important;
    height: 30px !important;
    opacity: 1 !important;
    background-image: linear-gradient(to right, #00c4ff, #0074ff);
  }
  .splide__arrow--prev svg,
  .splide__arrow--next svg {
    fill: white !important;
    height: 12px !important;
    width: 12px !important;
  }
  .splide__arrow--next {
    margin-right: 3px !important;
    // display: none;
  }
}

@media screen and (max-width: 2600px) {
  .Cardslider {
    .splide__arrow {
      width: 30px !important;
      height: 30px !important;
      opacity: 1 !important;
      background-image: linear-gradient(to right, #00c4ff, #0074ff);
    }
    .splide__arrow--prev svg,
    .splide__arrow--next svg {
      fill: white !important;
      height: 12px !important;
      width: 12px !important;
    }
    .splide__arrow--next {
      margin-right: -7px !important;
      // display: none;
    }
  }
  .ended {
    width: 11.5vw;
  }
}
@media screen and (max-width: 1980px) {
  .Cardslider {
    .splide__arrow {
      width: 30px !important;
      height: 30px !important;
      opacity: 1 !important;
      background-image: linear-gradient(to right, #00c4ff, #0074ff);
    }
    .splide__arrow--prev svg,
    .splide__arrow--next svg {
      fill: white !important;
      height: 12px !important;
      width: 12px !important;
    }
    .splide__arrow--next {
      margin-right: 1px !important;
      // display: none;
    }
  }
  // .banner-Buttons{
  //   width: 13vw;
  //   }
  //   .mainSlider{
  //     height: 53vh !important;
  //   }
  .ended {
    width: 13vw;
  }
}
@media screen and (max-width: 980px) {
  .mainSlider {
    height: 40vh !important;
  }
  .ended {
    margin-left: 30px;
  }
}
@media screen and (max-width: 790px) {
  .mainSlider {
    height: 45vh !important;
  }
}
@media screen and (max-width: 420px) {
  .ended {
    margin-left: 60px;
    margin-top: -35px;
  }
  .mainSlider {
    height: 53vh !important;
  }
}
@media screen and (max-width: 390px) {
  .ended {
    margin-left: 65px;
    // margin-top: -40px !important ;
  }
  .mainSlider {
    height: 58vh !important;
  }
}

// @media (max-width: 768px) {
//   .custom-splide .splide__arrow {
//     display: none;
//   }
// }